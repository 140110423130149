<template>
 <div id="ResultAfterChooseDomain">
  <div class="domain-available">
   <b-container>
    <b-row>
     <b-col>
      <div class="domain-available-container">
       <div class="domainResultSearch">
        <i class="far fa-check-circle"></i>
        <br />
        <p>Congratulations! <strong>abdalshafie.org</strong> is available!</p>
       </div>
       <div class="domain-price">
        <p class="register-price-label">Continue to register this domain for</p>
        <p class="transfer-price-label" hidden>
         Transfer to us and extend by 1 year* for
        </p>
        <h4 class="price">96.66 D.LY</h4>
       </div>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div hidden class="domain-unavailable">
   <b-container>
    <b-row>
     <b-col>
      <div class="domain-unavailable-container">
       <div class="domainResultSearch-unavailable">
        <i class="far fa-times-circle"></i>
        <br />
        <p><strong>abdalshafie.org</strong>is unavailable</p>
       </div>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div class="spotlight-tlds">
   <b-container>
    <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="6">
     <b-col>
      <div class="spotlight">
       <p>.com</p>
       <h2>10.59 DLY</h2>
       <button type="button">Add</button>
      </div>
     </b-col>
     <b-col>
      <div class="spotlight">
       <p>.com</p>
       <h2>10.59 DLY</h2>
       <button type="button">Add</button>
      </div>
     </b-col>
     <b-col>
      <div class="spotlight">
       <p>.com</p>
       <h2>10.59 DLY</h2>
       <button type="button">Add</button>
      </div>
     </b-col>
     <b-col>
      <div class="spotlight">
       <p>.com</p>
       <h2>10.59 DLY</h2>
       <button type="button">Add</button>
      </div>
     </b-col>
     <b-col>
      <div class="spotlight">
       <p>.com</p>
       <h2>10.59 DLY</h2>
       <button type="button">Add</button>
      </div>
     </b-col>
     <b-col>
      <div class="spotlight">
       <p>.com</p>
       <h2>10.59 DLY</h2>
       <button type="button">Add</button>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div class="suggested-domains">
   <b-container>
    <b-row>
     <b-col>
      <div class="panel-heading card-header">Suggested Domains</div>
      <ul class="domain-lookup-result">
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
       <li class="domain-suggestion">
        <span class="domain">almajdoup</span>
        <span class="extension">.co.com </span>
        <button type="button">Add To Cart</button>
        <span class="price">30.20 DLY</span>
       </li>
      </ul>
      <div class="domain-suggestions-warning">
       <p>
        Domain name suggestions may not always be available. Availability is
        checked in real-time at the point of adding to the cart.
       </p>
      </div>
      <div class="btnDomainContinue">
       <button type="submit">
        <router-link class="linkContinue" to="/ConfgProduct"
         >Continue</router-link
        >
        <i class="fa fa-arrow-circle-right"></i>
       </button>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
 </div>
</template>

<script>
export default {
 name: "ResultAfterChooseDomain",
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#ResultAfterChooseDomain {
 padding: 70px 0;
 .domain-available {
  .domain-available-container {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   .domainResultSearch {
    text-align: center;
    .fa-check-circle {
     font-weight: 400;
     font-size: 63px;
     background-color: #fff;
     margin-bottom: 20px;
     color: map-get($branding, domain-checker-available);
    }
    p {
     text-align: center;
     font-size: 20px;
     color: map-get($branding, domain-checker-available);

     strong {
      text-align: center;
      font-size: 20px;
      color: map-get($branding, domain-checker-available);
     }
    }
   }

   .domain-price {
    p {
     text-align: center;
     font-size: 20px;
     color: map-get($branding, domain-checker-available);
    }
    .price {
     text-align: center;
     font-family: $Numbers-font;
     font-weight: 700;
     display: block;
     font-size: 20px;
     line-height: 21px;
     color: #656565;
     border-radius: 10px;
    }
   }
  }
 }
 .domain-unavailable {
  .domain-unavailable-container {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   .domainResultSearch-unavailable {
    text-align: center;
    .fa-times-circle {
     font-weight: 400;
     font-size: 63px;
     background-color: #fff;
     margin-bottom: 20px;
     color: map-get($branding, domain-checker-unavailable);
    }
    p {
     text-align: center;
     font-size: 20px;
     color: map-get($branding, domain-checker-unavailable);
     text-align: center;
     font-size: 20px;
     color: map-get($branding, domain-checker-unavailable);
     strong {
     }
    }
   }
  }
 }
 .spotlight-tlds {
  padding: 70px 0;
  .spotlight {
   padding: 15px 0;
   background-color: #fafafa;
   border: 1px solid #c6c6c6;
   border-radius: 6px;
   text-align: center;
   font-weight: 700;
   margin: 0 0 10px 0;
   p {
    color: map-get($branding, color-text-numbers);
    font-family: $Paragraph-font;
    font-size: 12px;
    padding: 5px 0;
    margin: 3px 0;
   }
   h2 {
    color: map-get($branding, color-text-numbers);
    font-family: $Headings-font;
    font-size: 16px;
    line-height: 22px;
    padding: 0 0 5px 0;
    text-align: center;
    font-weight: 900;
   }
   button {
    background-color: map-get($branding, them-color-option-tow);
    border-color: map-get($branding, them-color-option-tow);
    color: map-get($color-texts, main-color);
    padding: 5px 48px;
    font-size: 13px;
    margin-bottom: 24px;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 3px;
   }
  }
 }
 .suggested-domains {
  .panel-heading {
   color: map-get($color-texts, main-color);
   font-family: $Paragraph-font;
   border-color: map-get($branding, them-color-option-tow);
   background-color: map-get($branding, them-color-option-tow);
   border-radius: 3px;
   padding: 13px 13px;
   font-size: 15px;
   font-weight: 400;
   margin-bottom: 4px;
  }
  .domain-lookup-result {
   padding: 0;
   margin: 0;
   .domain-suggestion {
    list-style: none;
    border: 1px solid #dcdcdc !important;
    padding: 17px 21px;
    margin-bottom: -1px;
    background-color: map-get($branding, main-color);
    font-family: $Paragraph-font;
    .domain {
     font-family: $Paragraph-font;
     color: map-get($color-texts, font-list);
     font-weight: 400;
     font-size: 14.1px;
    }
    .extension {
     font-weight: 700;
     font-size: 16px;
     color: map-get($branding, domain-checker-available);
     font-family: $Paragraph-font;
    }
    .price {
     float: right;
     margin-right: 13px;
     float: right;
     font-size: 15px;
     font-weight: 700;
     word-wrap: break-word;
     color: #212529;
    }
    button {
     float: right;
     background-color: map-get($branding, them-color-option-tow);
     border-color: map-get($branding, them-color-option-tow);
     border: none;
     text-align: center;
     color: map-get($color-texts, main-color);
     font-size: 12.5px;
     font-weight: 600;
     padding: 10px 20px;
     border-radius: 2px;
     margin-top: -5px;
     font-family: $Numbers-font;
     text-align: center;
    }
   }
  }
  .domain-suggestions-warning {
   padding: 10px 0;
   margin: 17px 0;
   p {
    color: #6c757d;
    font-size: 14px;
    text-align: center;
    margin: 0;
   }
  }
  .btnDomainContinue {
   display: flex;
   justify-content: center;
   align-items: center;
   button {
    border: 1px solid map-get($branding, them-color-option-tow);
    box-shadow: 0 2px 4px -1px map-get($branding, them-color-option-tow);
    border-color: map-get($branding, them-color-option-tow);
    background-color: map-get($branding, them-color-option-tow);
    border-radius: 4px;
    padding: 9px 25px;
    .linkContinue {
     color: map-get($color-texts, main-color);
     font-family: $Numbers-font;
     font-weight: 500;
     font-size: 1rem;
     text-decoration: none;
    }
    .fa-arrow-circle-right {
     color: map-get($color-texts, main-color);
     font-family: $Numbers-font;
     font-weight: 400;
     font-size: 1rem;
     margin-left: 3px;
    }
   }
  }
 }
}
</style>
