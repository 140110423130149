<template>
 <div id="Choose-A-Domain">
  <div class="RegisterAnewDomain">
   <b-container>
    <b-row>
     <b-col lg="7">
      <div class="tilte-section">
       <h2>Choose a Domain...</h2>
      </div>
      <section class="option-edit-domain">
       <div class="option-one">
        <header class="radio-option">
         <label>
          <div id="ins" class="radio-round">
           <input type="radio" />
           <ins v-on:click="showOption" class="iCheck-helper"></ins>
          </div>
          <span>Register a new domain</span>
         </label>
        </header>
        <section id="toggle" class="container-inputs">
         <b-container>
          <b-row class="group-inputs">
           <b-col class="input-static" lg="2">
            <input placeholder="WWW."
           /></b-col>
           <b-col class="input-name" lg="5"
            ><input type="text" placeholder="Enter Your Name Domain"
           /></b-col>
           <b-col class="input-domain" lg="3">
            <div class="container-serching-input">
             <span>.org </span>
             <span v-on:click="dropDown" class="btn-dropDown">
              <i id="btnShow" class="fas fa-chevron-circle-down"></i>
             </span>
            </div>
            <div id="dropDownMenue" class="chosen-drop">
             <div class="container-search">
              <div class="chosen-search">
               <input type="search" placeholder="Search Domain" />
              </div>
             </div>
             <ul class="chosen-results">
              <li>.com</li>
              <li>.net</li>
              <li>.ly</li>
              <li>.sa</li>
              <li>.qu</li>
              <li>.qu</li>
              <li>.qu</li>
              <li>.net</li>
             </ul>
            </div>
           </b-col>
           <b-col class="btnCheck" lg="2"><button>Check</button></b-col>
          </b-row>
         </b-container>
        </section>
       </div>
      </section>
      <section class="option-edit-domain">
       <div class="option-one">
        <header class="radio-option">
         <label>
          <div id="insTow" class="radio-round">
           <input type="radio" />
           <ins v-on:click="showOptionTow" class="iCheck-helper"></ins>
          </div>
          <span>Transfer your domain from another registrar</span>
         </label>
        </header>
        <section id="toggleTow" class="container-inputs">
         <b-container>
          <b-row class="group-inputs">
           <b-col class="input-static" lg="2">
            <input placeholder="WWW."
           /></b-col>
           <b-col class="input-name" lg="5"
            ><input type="text" placeholder="Enter Your Name Domain"
           /></b-col>
           <b-col class="input-domain" lg="3">
            <div class="container-serching-input">
             <span>.org </span>
             <span v-on:click="dropDownTow" class="btn-dropDown">
              <i id="btnShow" class="fas fa-chevron-circle-down"></i>
             </span>
            </div>
            <div id="dropDownMenuetow" class="chosen-drop">
             <div class="container-search">
              <div class="chosen-search">
               <input type="search" placeholder="Search Domain" />
              </div>
             </div>
             <ul class="chosen-results">
              <li>.com</li>
              <li>.net</li>
              <li>.ly</li>
              <li>.sa</li>
              <li>.qu</li>
              <li>.qu</li>
              <li>.qu</li>
              <li>.net</li>
             </ul>
            </div>
           </b-col>
           <b-col class="btnCheck" lg="2"><button>Transfer</button></b-col>
          </b-row>
         </b-container>
        </section>
       </div>
      </section>
      <section class="option-edit-domain">
       <div class="option-one">
        <header class="radio-option">
         <label>
          <div id="insThree" class="radio-round">
           <input type="radio" />
           <ins v-on:click="showOptionThree" class="iCheck-helper"></ins>
          </div>
          <span>Transfer your domain from another registrar</span>
         </label>
        </header>
        <section id="toggleThree" class="container-inputs">
         <b-container>
          <b-row class="group-inputs">
           <b-col class="input-static" lg="2">
            <input placeholder="WWW."
           /></b-col>
           <b-col class="input-name" lg="5"
            ><input type="text" placeholder="Enter Your Name Domain"
           /></b-col>
           <b-col class="input-domain" lg="3">
            <div class="container-serching-input">
             <span>.org </span>
             <span v-on:click="dropDownThree" class="btn-dropDown">
              <i id="btnShow" class="fas fa-chevron-circle-down"></i>
             </span>
            </div>
            <div id="dropDownMenueThree" class="chosen-drop">
             <div class="container-search">
              <div class="chosen-search">
               <input type="search" placeholder="Search Domain" />
              </div>
             </div>
             <ul class="chosen-results">
              <li>.com</li>
              <li>.net</li>
              <li>.ly</li>
              <li>.sa</li>
              <li>.qu</li>
              <li>.qu</li>
              <li>.qu</li>
              <li>.net</li>
             </ul>
            </div>
           </b-col>
           <b-col class="btnCheck" lg="2"><button>Use</button></b-col>
          </b-row>
         </b-container>
        </section>
       </div>
      </section>
     </b-col>
     <b-col lg="5"
      ><div class="img">
       <img
        src="../assets/Domain-Options-Edit.svg"
        alt="Domain-Options-Edit"
       /></div
     ></b-col>
    </b-row>
   </b-container>
  </div>
 </div>
</template>

<script>
export default {
 name: "Choose-A-Domain",
 methods: {
  //Register a new domain
  dropDown: function () {
   var dropDownMenue = document.getElementById("dropDownMenue");
   if (dropDownMenue.style.display == "block") {
    dropDownMenue.style.display = "none";
   } else {
    dropDownMenue.style.display = "block";
   }
  },
  showOption: function () {
   var show = document.getElementById("toggle");
   var ins = document.getElementById("ins");
   if (show.style.display == "block") {
    show.style.display = "none";
    ins.style.background = "#FFF";
   } else {
    show.style.display = "block";
    ins.style.background = "#06155a";
   }
  },
  //Transfer your domain from another registrar
  dropDownTow: function () {
   var dropDownMenuetow = document.getElementById("dropDownMenuetow");
   if (dropDownMenuetow.style.display == "block") {
    dropDownMenuetow.style.display = "none";
   } else {
    dropDownMenuetow.style.display = "block";
   }
  },
  showOptionTow: function () {
   var toggleTow = document.getElementById("toggleTow");
   var insTow = document.getElementById("insTow");
   if (toggleTow.style.display == "block") {
    toggleTow.style.display = "none";
    insTow.style.background = "#FFF";
   } else {
    toggleTow.style.display = "block";
    insTow.style.background = "#06155a";
   }
  },
  //I will use my existing domain and update my nameservers
  dropDownThree: function () {
   var dropDownMenuetow = document.getElementById("dropDownMenueThree");
   if (dropDownMenuetow.style.display == "block") {
    dropDownMenuetow.style.display = "none";
   } else {
    dropDownMenuetow.style.display = "block";
   }
  },
  showOptionThree: function () {
   var toggleTow = document.getElementById("toggleThree");
   var insTow = document.getElementById("insThree");
   if (toggleTow.style.display == "block") {
    toggleTow.style.display = "none";
    insTow.style.background = "#FFF";
   } else {
    toggleTow.style.display = "block";
    insTow.style.background = "#06155a";
   }
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#Choose-A-Domain {
 padding: 150px 0;
 background-color: map-get($branding, theme-color-main-dark);
 .tilte-section {
  margin: 35px 0;
  h2 {
   font-family: $Paragraph-font;
   font-weight: 600;
   line-height: 1.25;
   font-size: 2.29595rem;
   color: #fff;
  }
 }
 .option-edit-domain {
  background-color: map-get($branding, them-color-main-section);
  color: map-get($branding, color-text);
  margin-bottom: 15px;
  padding: 15px 20px;
  border-radius: 6px;
  padding: 20px 10px;
  .option-one {
   .radio-option {
    padding: 0 20px;
    label {
     margin-bottom: 0;
     display: flex;
     align-items: center;
     font-size: 14px;
     font-weight: 500;
    }
    .radio-round {
     position: relative;
     background-position: -120px 0;
     display: inline-block;
     vertical-align: middle;
     margin: 0;
     padding: 3px 2px !important;
     width: 24px;
     height: 24px;
     background: #fff;
     border: 1px solid #4c4c4c;
     border-radius: 3px;
     cursor: pointer;
     border-radius: 50%;
     /* border-color: #06155a;
    background: #06155a;
    min-width: 18px;
    min-height: 18px; */
     input {
      position: absolute;
      top: -20%;
      left: -20%;
      display: block;
      width: 140%;
      height: 140%;
      margin: 0px;
      padding: 0px;
      background: rgb(255, 255, 255);
      border: 0px;
      opacity: 0;
     }
     ins {
      position: absolute;
      top: -20%;
      left: -20%;
      display: block;
      width: 140%;
      height: 140%;
      margin: 0px;
      padding: 0px;
      background: rgb(255, 255, 255);
      border: 0px;
      opacity: 0;
     }
    }
    span {
     margin-left: 7px;
    }
    .radio-round::before {
     content: "\2713";
     height: 16px;
     width: 16px;
     color: #fff;
     font-family: "Font Awesome 5 Pro";
     font-weight: 900;
     line-height: 1;
     font-size: 21px;
    }
   }
   .container-inputs {
    margin-bottom: 15px;
    padding: 15px 20px;
    border-radius: 6px;
    background-color: #f7f5f8;
    .group-inputs {
     .input-static {
      padding: 0;
      margin: 0;
      input {
       text-align: center;
       width: 98%;
       background-color: #e9ecef;
       opacity: 1;
       border: 2px solid #06155a;
       padding: 3px 10px;
       cursor: not-allowed;
       font-size: 14px;
       height: 100%;
      }
     }

     .input-name {
      padding: 0;
      margin: 0 0;
      input {
       text-align: center;
       width: 99%;
       background-color: #f7f5f8;
       opacity: 1;
       border: 2px solid #06155a;
       padding: 3px 10px;
       font-size: 14px;
       height: 100%;
       color: #212529;
      }
     }
     .input-domain {
      position: relative;
      padding: 0;
      margin: 0;
      .container-serching-input {
       display: flex;
       justify-content: space-between;
       text-align: center;
       width: 99%;
       background-color: #f7f5f8;
       opacity: 1;
       border: 2px solid #06155a;
       padding: 3px 10px;

       span {
       }
       .btn-dropDown {
        .fa-chevron-circle-down {
         color: #06155a;
         font-size: 17px;
         cursor: pointer;
        }
       }
      }
      .chosen-drop {
       background: #fff;
       border: 2px solid #06155a;
       border-radius: 3px;
       position: absolute;
       width: 99%;
       margin-top: 3px;
       z-index: 9999999;
       height: 172px;
       overflow: auto;

       .container-search {
        position: relative;
        .chosen-search {
         position: static;
         input {
          width: 100%;
          border: 1px solid #06155a;
          color: #06155a;
          padding: 5px 6px;
          text-align: center;
         }
         ::placeholder {
          color: #06155a;
          font-size: 12px;
         }
        }
       }

       .chosen-results {
        width: 100%;
        padding: 0;
        margin: 0;
        li {
         padding: 4px 10px;
         width: 100%;
         list-style: none;
         cursor: pointer;
         border-bottom: 1px solid #06155a;
         border-right: 1px solid #06155a;
        }
        li:hover {
         background-color: #2ec1ff;
         color: #fff;
        }
        li:last-child {
         border: none;
        }
       }
      }
      .chosen-drop::-webkit-scrollbar {
       width: 10px;
      }
      .chosen-drop::-webkit-scrollbar-track {
       background-color: #eee;
      }
      .chosen-drop::-webkit-scrollbar-thumb {
       background-color: #06155a;
      }
      .chosen-drop::-webkit-scrollbar-thumb:hover {
       background-color: #06155a;
      }
     }
     .btnCheck {
      padding: 0;
      margin: 0;
      button {
       color: #FFF;
       border: 2px solid #06155a;
       border-radius: 3px;
       background-color: #06155a;
       text-align: center;
       font-size: 14px;
       width: 100%;
       height: 100%;
      }
     }
    }
   }
  }
 }
 .img {
  width: 100%;
  img {
   width: 100%;
  }
 }
}
</style>
