<template>
 <div id="cart">
<!--  <Modals /> -->
  <Navigation/>
  <choose-a-domain />
  <ResultAfterChooseDomain />
  <partners />
  <Footer />
 </div>
</template>

<script>
import ChooseADomain from "../components/Choose-A-Domain.vue";
import Partners from "../../home/components/Partners.vue";
import Footer from "../../home/components/Footer.vue";
import ResultAfterChooseDomain from "../components/ResultAfterChooseDomain.vue";
import Navigation from "../../home/components/Navigation.vue";
/* import Modals from "../../../components/Modals.vue"; */ 

export default {
 components: {
    ChooseADomain,
    Partners,
    Footer , /* Modals , */
    ResultAfterChooseDomain,
    Navigation
},
};
</script>

<style lang="scss" scoped>
/* #cart {
 z-index: 0;
 background-color: red;
 opacity: 0.5;
} */
</style>
